export default [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'feature',
    label: 'Portfolio',
  },
  {
    path: 'support',
    label: 'Team',
  },
];
